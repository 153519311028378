export const Lens = () => {
  return (
    <>
      <svg
        width="959"
        height="1155"
        viewBox="0 0 959 1155"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="absolute left-[-460px] top-[190px] hidden lg:block -z-[0] pointer-events-none"
      >
        <g opacity="0.2">
          <path
            d="M326.967 364.904L81.7417 506.485L81.7417 223.323L326.967 364.904Z"
            stroke="#C93D94"
            strokeWidth="0.793897"
            strokeLinejoin="round"
          />
          <path
            d="M817.418 364.904L572.193 506.485L572.193 223.323L817.418 364.904Z"
            stroke="#C93D94"
            strokeWidth="0.793897"
            strokeLinejoin="round"
          />
          <path
            d="M572.192 789.819L326.966 931.4L326.966 648.238L572.192 789.819Z"
            stroke="#C93D94"
            strokeWidth="0.793897"
            strokeLinejoin="round"
          />
          <path
            d="M326.967 364.904L572.192 506.485L572.192 223.323L326.967 364.904Z"
            stroke="#C93D94"
            strokeWidth="0.793897"
            strokeLinejoin="round"
          />
          <path
            d="M572.192 789.819L817.417 931.4L817.417 648.238L572.192 789.819Z"
            stroke="#C93D94"
            strokeWidth="0.793897"
            strokeLinejoin="round"
          />
          <path
            d="M572.193 223.325L326.968 81.7439V364.906L572.193 223.325Z"
            stroke="#C93D94"
            strokeWidth="0.793897"
            strokeLinejoin="round"
          />
          <path
            d="M817.418 648.242L572.193 506.661V789.823L817.418 648.242Z"
            stroke="#C93D94"
            strokeWidth="0.793897"
            strokeLinejoin="round"
          />
          <path
            d="M572.192 506.487L326.966 364.906V648.068L572.192 506.487Z"
            stroke="#C93D94"
            strokeWidth="0.793897"
            strokeLinejoin="round"
          />
          <path
            d="M817.418 931.403L572.193 789.822V1072.98L817.418 931.403Z"
            stroke="#C93D94"
            strokeWidth="0.793897"
            strokeLinejoin="round"
          />
          <path
            d="M326.97 364.903L326.97 81.7417L81.7444 223.323L326.97 364.903Z"
            stroke="#C93D94"
            strokeWidth="0.793897"
            strokeLinejoin="round"
          />
          <path
            d="M572.194 789.82L572.194 506.659L326.969 648.24L572.194 789.82Z"
            stroke="#C93D94"
            strokeWidth="0.793897"
            strokeLinejoin="round"
          />
          <path
            d="M326.97 648.066L326.97 364.904L81.7444 506.485L326.97 648.066Z"
            stroke="#C93D94"
            strokeWidth="0.793897"
            strokeLinejoin="round"
          />
          <path
            d="M817.421 648.066L817.421 364.904L572.196 506.485L817.421 648.066Z"
            stroke="#C93D94"
            strokeWidth="0.793897"
            strokeLinejoin="round"
          />
          <path
            d="M572.194 1072.98L572.194 789.82L326.969 931.401L572.194 1072.98Z"
            stroke="#C93D94"
            strokeWidth="0.793897"
            strokeLinejoin="round"
          />
        </g>
      </svg>

      <svg
        width="636"
        height="1101"
        viewBox="0 0 636 1101"
        fill="none"
        className="absolute right-0 top-[-300px] hidden lg:block -z-[0] pointer-events-none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          style={{ mixBlendMode: 'hard-light' }}
          opacity="0.3"
          filter="url(#filter0_f_7006_1349)"
        >
          <ellipse
            cx="599.815"
            cy="550.289"
            rx="373.466"
            ry="158.163"
            transform="rotate(-142.079 599.815 550.289)"
            fill="#2E42FF"
          />
        </g>
        <defs>
          <filter
            id="filter0_f_7006_1349"
            x="0.57666"
            y="0.0779419"
            width="1198.48"
            height="1100.42"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood
              floodOpacity="0"
              result="BackgroundImageFix"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feGaussianBlur
              stdDeviation="144.46"
              result="effect1_foregroundBlur_7006_1349"
            />
          </filter>
        </defs>
      </svg>

      <svg
        width="959"
        height="1155"
        viewBox="0 0 959 1155"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="absolute right-[-300px] top-[-510px] hidden lg:block -z-[0] pointer-events-none"
      >
        <g opacity="0.2">
          <path
            d="M326.967 364.904L81.7417 506.485L81.7417 223.323L326.967 364.904Z"
            stroke="#C93D94"
            strokeWidth="0.793897"
            strokeLinejoin="round"
          />
          <path
            d="M817.418 364.904L572.193 506.485L572.193 223.323L817.418 364.904Z"
            stroke="#C93D94"
            strokeWidth="0.793897"
            strokeLinejoin="round"
          />
          <path
            d="M572.192 789.819L326.966 931.4L326.966 648.238L572.192 789.819Z"
            stroke="#C93D94"
            strokeWidth="0.793897"
            strokeLinejoin="round"
          />
          <path
            d="M326.967 364.904L572.192 506.485L572.192 223.323L326.967 364.904Z"
            stroke="#C93D94"
            strokeWidth="0.793897"
            strokeLinejoin="round"
          />
          <path
            d="M572.192 789.819L817.417 931.4L817.417 648.238L572.192 789.819Z"
            stroke="#C93D94"
            strokeWidth="0.793897"
            strokeLinejoin="round"
          />
          <path
            d="M572.193 223.325L326.968 81.7439V364.906L572.193 223.325Z"
            stroke="#C93D94"
            strokeWidth="0.793897"
            strokeLinejoin="round"
          />
          <path
            d="M817.418 648.242L572.193 506.661V789.823L817.418 648.242Z"
            stroke="#C93D94"
            strokeWidth="0.793897"
            strokeLinejoin="round"
          />
          <path
            d="M572.192 506.487L326.966 364.906V648.068L572.192 506.487Z"
            stroke="#C93D94"
            strokeWidth="0.793897"
            strokeLinejoin="round"
          />
          <path
            d="M817.418 931.403L572.193 789.822V1072.98L817.418 931.403Z"
            stroke="#C93D94"
            strokeWidth="0.793897"
            strokeLinejoin="round"
          />
          <path
            d="M326.97 364.903L326.97 81.7417L81.7444 223.323L326.97 364.903Z"
            stroke="#C93D94"
            strokeWidth="0.793897"
            strokeLinejoin="round"
          />
          <path
            d="M572.194 789.82L572.194 506.659L326.969 648.24L572.194 789.82Z"
            stroke="#C93D94"
            strokeWidth="0.793897"
            strokeLinejoin="round"
          />
          <path
            d="M326.97 648.066L326.97 364.904L81.7444 506.485L326.97 648.066Z"
            stroke="#C93D94"
            strokeWidth="0.793897"
            strokeLinejoin="round"
          />
          <path
            d="M817.421 648.066L817.421 364.904L572.196 506.485L817.421 648.066Z"
            stroke="#C93D94"
            strokeWidth="0.793897"
            strokeLinejoin="round"
          />
          <path
            d="M572.194 1072.98L572.194 789.82L326.969 931.401L572.194 1072.98Z"
            stroke="#C93D94"
            strokeWidth="0.793897"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    </>
  );
};
