'use client';

import { CaseCard } from '@/components/Cards/CaseCard';
import { Lens } from './Lens';
import { ArrowButtonLink } from '@/components/Links/ArrowButtonLink';
import clsx from 'clsx';

interface IProps {
  data: {
    id: string;
    video?: string | null;
    image?: {
      path: string;
      width: number;
      height: number;
    } | null;
    title: string;
    description: string;
    href: string;
    tags: {
      title: string;
    }[];
  }[];
  link: {
    to: string;
    title: string;
  };
  withButton?: boolean;
  withLens?: boolean;
  titleSlot?: boolean;
  wrapperClassName?: string;
  purpleView?: boolean;
}

export const Cases = ({
  data,
  link,
  withButton = true,
  withLens = true,
  titleSlot = false,
  wrapperClassName,
  purpleView = false,
}: IProps) => (
  <div
    className={clsx(
      `bg-black-11 flex flex-col justify-center relative overflow-x-clip gap-[40px]
        mobile:gap-[55px] items-center pt-[40px] pb-[40px] mobile:pb-[80px] px-[16px]`,
      {
        [`${wrapperClassName}`]: wrapperClassName,
      },
    )}
  >
    {titleSlot && (
      <div className="p-base flex justify-center w-full">
        <div className="w-full max-w-[1376px]">
          <h3 className="intro-pre-title mb-xs !mb-0 !text-green-7">Our work</h3>
        </div>
      </div>
    )}
    <div
      className="relative flex gap-[40px] justify-center container p-0
      flex-wrap [@media(min-width:1440px)]:gap-[28px] [@media(min-width:1440px)]:gap-[28px]"
    >
      {data.map(({ ...rest }, index) => (
        <CaseCard
          purpleView={purpleView}
          animated
          {...rest}
          key={index}
        />
      ))}
    </div>
    {withLens && <Lens />}
    {withButton && (
      <ArrowButtonLink
        arrowVariant="secondary"
        gradientPosition="!h-[1000%]"
        gradient="bg-conic-gradient-green"
        buttonTextClassname="!font-normal"
        buttonClassname="text-white flex mt-[32px] lg:mt-0 whitespace-nowrap
        w-[133.77px] h-[53.02px] text-[17.67px] leading-[17.67px]
        mobile:w-[418px] mobile:h-[48px] mobile:text-[16px] mobile:leading-[22px] border-white50"
        to={link.to}
      >
        {link.title}
      </ArrowButtonLink>
    )}
  </div>
);
