import Link from 'next/link';
import { PropsWithChildren } from 'react';
import { ConicBackground } from '@/components/Backgrounds/Conic';
import clsx from 'clsx';
import { Arrow } from '@/images/svg/Arrow';
import { TConicGradientClasses } from '@/interfaces/layout';

interface IProps extends PropsWithChildren {
  testId?: string;
  buttonClassname?: string;
  arrowVariant?: 'primary' | 'secondary';
  to: string;
  gradient?: TConicGradientClasses;
  withArrow?: boolean;
  gradientPosition?: string;
  disabled?: boolean;
  buttonTextClassname?: string;
}

export const ArrowButtonLink = ({
  testId,
  children,
  to,
  gradient = 'bg-conic-gradient-light',
  buttonClassname,
  buttonTextClassname,
  arrowVariant = 'primary',
  withArrow = true,
  gradientPosition,
  disabled,
  ...rest
}: IProps) => {
  return (
    <Link
      scroll={true}
      data-testid={testId}
      href={to}
      className={clsx(
        `flex group items-center justify-center  
        ${disabled && 'pointer-events-none opacity-60'}
        relative overflow-hidden rounded-full 
        px-base py-[13.5px] border-[0.5px] min-w-[111px] isolate`,
        {
          [`${buttonClassname}`]: buttonClassname,
        },
      )}
      {...rest}
    >
      {disabled && <div className="w-full h-full absolute backdrop-grayscale z-20"></div>}

      <div
        className={clsx(`relative z-[1] font-semibold leading-base flex items-center gap-[4px] group`, {
          [`${buttonTextClassname}`]: buttonTextClassname,
        })}
      >
        {children}
        {withArrow && <Arrow variant={arrowVariant} />}
      </div>

      <ConicBackground
        gradient={gradient}
        classname={clsx('w-[310%] h-[1000%]', { [`${gradientPosition}`]: gradientPosition })}
      />
    </Link>
  );
};
