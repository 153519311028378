'use client';

import { LoopingVideo } from '@/components/LoopingVideo';
import Link from 'next/link';
import { routeNames } from '@/constants/routes';
import { useRef, useState } from 'react';
import { AnimationBlock } from '@/components/AnimationBlock';
import { Arrow } from '@/images/svg/Arrow';
import Image from 'next/image';
import clsx from 'clsx';

interface IProps {
  id: string;
  title: string;
  tags: {
    title: string;
  }[];
  description: string;
  video?: string | null;
  image?: {
    path: string;
    width: number;
    height: number;
  } | null;
  href: string;
  animated?: boolean;
  purpleView?: boolean;
}

const getCardBackgroundColor = (purpleView = false, isViewAllCard = false) => {
  const viewAllColor = purpleView ? 'bg-green-9' : 'bg-purple-9';
  const nonViewAllColor = purpleView ? 'bg-purple-15' : 'bg-purple-14';

  return isViewAllCard ? viewAllColor : nonViewAllColor;
};

const getCardCornerColor = (purpleView = false, isViewAllCard = false) => {
  const viewAllColor = purpleView ? '#239745' : '#862963';
  const nonViewAllColor = purpleView ? '#943175' : '#3A275A';

  return isViewAllCard ? viewAllColor : nonViewAllColor;
};

const getTitleColor = (purpleView = false, isViewAllCard = false) => {
  const viewAllColor = purpleView ? 'text-grey-9' : 'text-green-7';
  const nonViewAllColor = purpleView ? 'text-green-7' : 'text-purple-7';

  return isViewAllCard ? viewAllColor : nonViewAllColor;
};

export const CaseCard = ({
  description,
  title,
  video,
  tags,
  href,
  animated = false,
  id,
  image,
  purpleView,
}: IProps) => {
  const blockRef = useRef(null);
  const [isVideoPlaying, setVideoPlay] = useState(false);

  const isViewAllCard = id === 'all';

  const content = (
    <div
      className={`
        flex flex-col max-w-[320px] h-[583px] w-full
        relative ${getCardBackgroundColor(purpleView, isViewAllCard)} rounded-[20px] [&_video]:rounded-t-[10px]
        mobile:max-w-[443px] group z-[2] shadow-lg overflow-hidden
      `}
      onMouseOver={() => video && setVideoPlay(true)}
      onMouseLeave={() => video && setVideoPlay(false)}
    >
      <div className={`relative ${isViewAllCard ? 'bg-black-11' : ''}`}>
        {video ? (
          <LoopingVideo
            src={video}
            className="!z-[1] !m-0 !w-full max-w-[320px] mobile:max-w-[443px] max-h-[289px] z-[1]"
            isPlaying={isVideoPlaying}
          />
        ) : (
          image && (
            <Image
              src={image.path}
              width={image.width}
              height={image.height}
              alt="Logo"
              loading="lazy"
              placeholder="empty"
              className="object-cover max-h-[249px] rounded-[8px]"
            />
          )
        )}

        <svg
          width="140"
          height="87"
          viewBox="0 0 140 87"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="absolute bottom-0 right-0 z-[2]"
        >
          <path
            d="M140 0V87H0L140 0Z"
            fill={getCardCornerColor(purpleView, isViewAllCard)}
          />
        </svg>
      </div>

      <div className="z-[3] p-[30px] h-full flex flex-col">
        <h3 className={`${getTitleColor(purpleView, isViewAllCard)} uppercase font-medium mb-[5px]`}>{title}</h3>
        {description && (
          <Link href={`${href}#nav-header`}>
            <p
              className="
                text-grey-1 text-4xl leading-[43.2px]
                transition-colors duration-300 ease-out group-hover:text-purple-7
              "
            >
              {description}
            </p>
          </Link>
        )}

        <div className="flex justify-between items-center mt-[auto]">
          {tags && (
            <div className="flex gap-[8px] flex-wrap">
              {tags.map((tag) => {
                return (
                  <Link
                    key={tag.title}
                    href={{
                      pathname: routeNames.ourWork,
                      query: { category: tag.title, page: 1 },
                    }}
                    className="hover:scale-[1.05] duration-300"
                  >
                    <span
                      className="
                        transition-colors duration-300 ease-out
                        flex items-center justify-center bg-transparent
                        rounded-full border-[1px] px-[10px] py-[5px]
                        font-medium text-center
                        text-white border-white
                      "
                    >
                      {tag.title}
                    </span>
                  </Link>
                );
              })}
            </div>
          )}
          <div className="flex items-center">
            <Link
              href={`${href}#nav-header`}
              className={clsx('hover:opacity-80 transition duration-300 ease-out hover:scale-[1.05]', {
                [`text-white`]: purpleView,
                [`text-green-7`]: !purpleView,
              })}
            >
              Expand
            </Link>
            <Arrow variant={`${purpleView ? 'secondary' : 'home-green'}`} />
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div ref={blockRef}>
      {animated ? (
        <AnimationBlock
          id={`card-anim-${id}`}
          blockRef={blockRef}
        >
          {content}
        </AnimationBlock>
      ) : (
        content
      )}
    </div>
  );
};
